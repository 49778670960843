.add-form {
    width: 800px;
    margin: 50px auto;
}

.add-form label {
    display: block;
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 25px;
}

.add-form label small {
    font-weight: normal;
}

.add-form input, .add-form textarea {
    width: 100%;
    margin-top: 10px;
    padding: 10px 0px;
}

.add-form button {
    padding: 10px;
    width: 100%;
}
